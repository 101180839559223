var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative","height":"calc(100vh - 100px)","overflow-y":"scroll"}},[_c('Breadcrumb',{attrs:{"Breadcrumb":_vm.BreadcrumbCon}}),_c('div',{staticClass:"activity_create_back",on:{"click":_vm.handleBack}},[_c('el-button',{attrs:{"type":"text"}},[_c('i',{staticClass:"el-icon-arrow-left"}),_vm._v("返回活动列表")])],1),_vm._l((_vm.detailRow),function(item,i){return _c('div',{key:i},[(
        item.worldActivityOrderResponse.bizOrder.orderStatus == '支付审核中'
      )?_c('div',{staticClass:"activity_create_bg"},[_c('h4',[_vm._v(" 订单状态："+_vm._s(item.worldActivityOrderResponse.bizOrder.orderStatus)+" ")]),_c('p',[_vm._v("订单号："+_vm._s(item.payExamineVO.orderId))]),_c('p',[_vm._v(" 审核剩余时间："),_c('span',[_vm._v(_vm._s(_vm.day))]),_vm._v("天"),_c('span',[_vm._v(_vm._s(_vm.hour))]),_vm._v("时"),_c('span',[_vm._v(_vm._s(_vm.minute))]),_vm._v("分"),_c('span',[_vm._v(_vm._s(_vm.second))]),_vm._v("秒 ")]),_c('div',{staticStyle:{"display":"flex"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleClick('success', item.payExamineVO)}}},[_vm._v("审核通过")]),_c('div',{staticClass:"activity_examine_reason"},[_c('el-input',{attrs:{"placeholder":"请输入拒绝原因"},model:{value:(_vm.failReason),callback:function ($$v) {_vm.failReason=$$v},expression:"failReason"}}),_c('el-button',{attrs:{"disabled":_vm.failReason == ''},on:{"click":function($event){return _vm.handleClick('fail', item.payExamineVO)}}},[_vm._v("审核拒绝")])],1)],1)]):(
        item.worldActivityOrderResponse.bizOrder.orderStatus == '支付审核拒绝'
      )?_c('div',{staticClass:"activity_create_bg"},[_c('h4',[_vm._v(" 订单状态："+_vm._s(item.worldActivityOrderResponse.bizOrder.orderStatus)+" ")]),_c('p',[_vm._v("订单号："+_vm._s(item.payExamineVO.orderId))]),_c('p',[_vm._v("未通过理由："+_vm._s(item.payExamineVO.examineReason))]),_c('el-button',{staticStyle:{"margin-left":"20px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleClick('success', item.payExamineVO)}}},[_vm._v("审核通过")])],1):(
        item.worldActivityOrderResponse.bizOrder.orderStatus ==
          '活动已预订，未开始' ||
        item.worldActivityOrderResponse.bizOrder.orderStatus ==
          '活动已预订，进行中'
      )?_c('div',{staticClass:"activity_create_bg"},[(
          item.worldActivityOrderResponse.activityDTO.activityStatus ==
          '活动结束'
        )?_c('h4',[_vm._v(" 订单状态：活动结束 ")]):(
          item.worldActivityOrderResponse.activityDTO.activityStatus ==
          '活动进行中'
        )?_c('h4',[_vm._v(" 订单状态：活动进行中 ")]):(
          item.worldActivityOrderResponse.activityDTO.activityStatus ==
            '报名中' ||
          item.worldActivityOrderResponse.activityDTO.activityStatus ==
            '报名结束'
        )?_c('h4',[_vm._v(" 订单状态：活动未开始 ")]):_vm._e(),_c('p',[_vm._v("订单号："+_vm._s(item.payExamineVO.orderId))]),_c('p',[_vm._v(" 报名人："+_vm._s(item.worldActivityOrderResponse.bizOrder.contactName)+" ")]),_c('p',[_vm._v(" 报名人联系方式："+_vm._s(item.worldActivityOrderResponse.bizOrder.contactPhone)+" ")]),_c('p',[_vm._v(" 报名人邮箱："+_vm._s(item.worldActivityOrderResponse.bizOrder.contactEmail)+" ")]),_c('div',{staticClass:"activity_examine_reason",staticStyle:{"width":"370px"}},[_c('el-input',{attrs:{"placeholder":"请输入取消原因"},model:{value:(_vm.cancelReason),callback:function ($$v) {_vm.cancelReason=$$v},expression:"cancelReason"}}),_c('el-button',{attrs:{"disabled":_vm.cancelReason == ''},on:{"click":function($event){return _vm.handleClick(
              'cancel',
              item.payExamineVO,
              item.worldActivityOrderResponse.payOrder
            )}}},[_vm._v("取消")])],1)]):(
        item.worldActivityOrderResponse.bizOrder.orderStatus == '平台取消'
      )?_c('div',{staticClass:"activity_create_bg"},[_c('h4',[_vm._v("订单状态：平台取消")]),_c('p',[_vm._v("订单号："+_vm._s(item.payExamineVO.orderId))]),_c('p',[_vm._v("取消原因："+_vm._s(item.bizOrder.cancelReason))])]):(
        item.worldActivityOrderResponse.bizOrder.orderStatus == '用户取消'
      )?_c('div',{staticClass:"activity_create_bg"},[_c('h4',[_vm._v("订单状态：用户取消，已退订")]),_c('p',[_vm._v("订单号："+_vm._s(item.payExamineVO.orderId))]),_c('p',[_vm._v(" 退订原因："+_vm._s(item.worldActivityOrderResponse.bizOrder.cancelType)+" ")])]):_vm._e(),(
        item.worldActivityOrderResponse.bizOrder.orderStatus ==
          '支付审核中' ||
        item.worldActivityOrderResponse.bizOrder.orderStatus ==
          '支付审核拒绝' ||
        item.worldActivityOrderResponse.bizOrder.orderStatus == '平台取消'
      )?_c('div',[_c('div',{staticClass:"activity_create_bg"},[_c('h4',[_vm._v("支付信息")]),_c('p',[_vm._v(" 支付金额："+_vm._s(item.worldActivityOrderResponse.payOrder.payType == 1 ? item.worldActivityOrderResponse.payOrder.amount : item.worldActivityOrderResponse.payOrder.payType == 7 ? item.worldActivityOrderResponse.payOrder.amountUsdt : item.worldActivityOrderResponse.payOrder.payType == 8 ? item.worldActivityOrderResponse.payOrder.amountToken : "$ 0")+" ")]),_c('p',{staticStyle:{"display":"flex"}},[_vm._v(" 支付截图： "),_c('el-image',{staticStyle:{"width":"100px","height":"150px"},attrs:{"src":item.payExamineVO.payImg,"preview-src-list":[item.payExamineVO.payImg]}})],1),_c('p',[_vm._v("支付方式："+_vm._s(item.payExamineVO.payType))])]),_c('div',{staticClass:"activity_create_bg"},[_c('h4',[_vm._v("订单信息")]),_c('p',[_vm._v("订单号："+_vm._s(item.worldActivityOrderResponse.payOrder.orderId))]),_c('p',[_vm._v(" 下单时间："+_vm._s(item.worldActivityOrderResponse.payOrder.createTime)+" ")]),_c('p',[_vm._v(" 付款时间："+_vm._s(item.worldActivityOrderResponse.payOrder.payTime)+" ")]),_c('div',{staticClass:"activity_create_bg_detailRow"},[_c('div',[_c('el-image',{staticStyle:{"width":"200px","height":"130px"},attrs:{"src":item.worldActivityOrderResponse.activityDTO.primaryImage,"preview-src-list":[
                item.worldActivityOrderResponse.activityDTO.primaryImage,
              ]}})],1),_c('p',[_vm._v(" "+_vm._s(item.worldActivityOrderResponse.activityDTO.activityTitle)+" ")]),_c('p',[_vm._v(" 单价："+_vm._s(item.worldActivityOrderResponse.activityDTO.activityPrice)+" ")]),_c('p',[_vm._v(" 库存："+_vm._s(item.worldActivityOrderResponse.activityDTO.activityStock)+" ")]),_c('p',[_vm._v(" 状态："+_vm._s(item.worldActivityOrderResponse.activityDTO.activityStatus)+" ")]),_c('p')])]),_c('div',{staticClass:"activity_create_bg"},[_c('h4',[_vm._v("活动信息")]),_c('p',[_vm._v(" 活动日期："+_vm._s(item.worldActivityOrderResponse.activityDTO.startTime)+"——"+_vm._s(item.worldActivityOrderResponse.activityDTO.endTime)+" ")]),_c('p',[_vm._v(" 活动地址："+_vm._s(item.worldActivityOrderResponse.activityDTO.activityLocation)+" ")])]),_c('div',{staticClass:"activity_create_bg"},[_c('h4',[_vm._v("预定人信息")]),_vm._l((item.worldActivityOrderResponse.bizOrder
            .playerList),function(it,index){return _c('div',{key:index},[_c('div',[_c('p',{staticStyle:{"width":"350px"}},[_vm._v(" 联系人："+_vm._s(it.contactLastname)+_vm._s(it.contactFirstname)+" ")]),_c('p',[_vm._v(" 手机号："+_vm._s(item.worldActivityOrderResponse.bizOrder.contactPhone)+" ")]),_c('p',[_vm._v(" 邮箱："+_vm._s(item.worldActivityOrderResponse.bizOrder.contactEmail)+" ")]),_c('p',[_vm._v(" 护照ID： "+_vm._s(it.passportId)+" ")]),_c('p',[_vm._v(" 护照有效期： "+_vm._s(it.passportEnd)+" ")])])])})],2)]):_vm._e()])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }