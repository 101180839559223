<template>
  <div
    style="position: relative; height: calc(100vh - 100px); overflow-y: scroll"
  >
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />

    <div class="activity_create_back" @click="handleBack">
      <el-button type="text">
        <i class="el-icon-arrow-left"></i>返回活动列表</el-button
      >
    </div>
    <div v-for="(item, i) in detailRow" :key="i">
      <div
        class="activity_create_bg"
        v-if="
          item.worldActivityOrderResponse.bizOrder.orderStatus == '支付审核中'
        "
      >
        <h4>
          订单状态：{{ item.worldActivityOrderResponse.bizOrder.orderStatus }}
        </h4>
        <p>订单号：{{ item.payExamineVO.orderId }}</p>
        <p>
          审核剩余时间：<span>{{ day }}</span
          >天<span>{{ hour }}</span
          >时<span>{{ minute }}</span
          >分<span>{{ second }}</span
          >秒
        </p>
        <div style="display: flex">
          <el-button
            type="primary"
            @click="handleClick('success', item.payExamineVO)"
            >审核通过</el-button
          >
          <div class="activity_examine_reason">
            <el-input
              placeholder="请输入拒绝原因"
              v-model="failReason"
            ></el-input>
            <el-button
              :disabled="failReason == ''"
              @click="handleClick('fail', item.payExamineVO)"
              >审核拒绝</el-button
            >
          </div>
        </div>
      </div>

      <div
        class="activity_create_bg"
        v-else-if="
          item.worldActivityOrderResponse.bizOrder.orderStatus == '支付审核拒绝'
        "
      >
        <h4>
          订单状态：{{ item.worldActivityOrderResponse.bizOrder.orderStatus }}
        </h4>
        <p>订单号：{{ item.payExamineVO.orderId }}</p>
        <p>未通过理由：{{ item.payExamineVO.examineReason }}</p>

        <el-button
          type="primary"
          @click="handleClick('success', item.payExamineVO)"
          style="margin-left: 20px"
          >审核通过</el-button
        >
      </div>

      <div
        class="activity_create_bg"
        v-else-if="
          item.worldActivityOrderResponse.bizOrder.orderStatus ==
            '活动已预订，未开始' ||
          item.worldActivityOrderResponse.bizOrder.orderStatus ==
            '活动已预订，进行中'
        "
      >
        <h4
          v-if="
            item.worldActivityOrderResponse.activityDTO.activityStatus ==
            '活动结束'
          "
        >
          订单状态：活动结束
        </h4>
        <h4
          v-else-if="
            item.worldActivityOrderResponse.activityDTO.activityStatus ==
            '活动进行中'
          "
        >
          订单状态：活动进行中
        </h4>
        <h4
          v-else-if="
            item.worldActivityOrderResponse.activityDTO.activityStatus ==
              '报名中' ||
            item.worldActivityOrderResponse.activityDTO.activityStatus ==
              '报名结束'
          "
        >
          订单状态：活动未开始
        </h4>
        <p>订单号：{{ item.payExamineVO.orderId }}</p>
        <p>
          报名人：{{ item.worldActivityOrderResponse.bizOrder.contactName }}
        </p>
        <p>
          报名人联系方式：{{
            item.worldActivityOrderResponse.bizOrder.contactPhone
          }}
        </p>
        <p>
          报名人邮箱：{{
            item.worldActivityOrderResponse.bizOrder.contactEmail
          }}
        </p>
        <div class="activity_examine_reason" style="width: 370px">
          <el-input
            placeholder="请输入取消原因"
            v-model="cancelReason"
          ></el-input>
          <el-button
            :disabled="cancelReason == ''"
            @click="
              handleClick(
                'cancel',
                item.payExamineVO,
                item.worldActivityOrderResponse.payOrder
              )
            "
            >取消</el-button
          >
        </div>
      </div>

      <div
        class="activity_create_bg"
        v-else-if="
          item.worldActivityOrderResponse.bizOrder.orderStatus == '平台取消'
        "
      >
        <h4>订单状态：平台取消</h4>
        <p>订单号：{{ item.payExamineVO.orderId }}</p>
        <p>取消原因：{{ item.bizOrder.cancelReason }}</p>
      </div>

      <div
        class="activity_create_bg"
        v-else-if="
          item.worldActivityOrderResponse.bizOrder.orderStatus == '用户取消'
        "
      >
        <h4>订单状态：用户取消，已退订</h4>
        <p>订单号：{{ item.payExamineVO.orderId }}</p>
        <p>
          退订原因：{{ item.worldActivityOrderResponse.bizOrder.cancelType }}
        </p>
      </div>

      <div
        v-if="
          item.worldActivityOrderResponse.bizOrder.orderStatus ==
            '支付审核中' ||
          item.worldActivityOrderResponse.bizOrder.orderStatus ==
            '支付审核拒绝' ||
          item.worldActivityOrderResponse.bizOrder.orderStatus == '平台取消'
        "
      >
        <div class="activity_create_bg">
          <h4>支付信息</h4>
          <p>
            支付金额：{{
              item.worldActivityOrderResponse.payOrder.payType == 1
                ? item.worldActivityOrderResponse.payOrder.amount
                : item.worldActivityOrderResponse.payOrder.payType == 7
                ? item.worldActivityOrderResponse.payOrder.amountUsdt
                : item.worldActivityOrderResponse.payOrder.payType == 8
                ? item.worldActivityOrderResponse.payOrder.amountToken
                : "$ 0"
            }}
          </p>
          <p style="display: flex">
            支付截图：
            <el-image
              style="width: 100px; height: 150px"
              :src="item.payExamineVO.payImg"
              :preview-src-list="[item.payExamineVO.payImg]"
            >
            </el-image>
          </p>
          <p>支付方式：{{ item.payExamineVO.payType }}</p>
        </div>

        <div class="activity_create_bg">
          <h4>订单信息</h4>
          <p>订单号：{{ item.worldActivityOrderResponse.payOrder.orderId }}</p>
          <p>
            下单时间：{{ item.worldActivityOrderResponse.payOrder.createTime }}
          </p>
          <p>
            付款时间：{{ item.worldActivityOrderResponse.payOrder.payTime }}
          </p>
          <div class="activity_create_bg_detailRow">
            <div>
              <el-image
                style="width: 200px; height: 130px"
                :src="item.worldActivityOrderResponse.activityDTO.primaryImage"
                :preview-src-list="[
                  item.worldActivityOrderResponse.activityDTO.primaryImage,
                ]"
              >
              </el-image>
            </div>
            <p>
              {{ item.worldActivityOrderResponse.activityDTO.activityTitle }}
            </p>
            <p>
              单价：{{
                item.worldActivityOrderResponse.activityDTO.activityPrice
              }}
            </p>
            <p>
              库存：{{
                item.worldActivityOrderResponse.activityDTO.activityStock
              }}
            </p>
            <p>
              状态：{{
                item.worldActivityOrderResponse.activityDTO.activityStatus
              }}
            </p>
            <p></p>
          </div>
        </div>

        <div class="activity_create_bg">
          <h4>活动信息</h4>
          <p>
            活动日期：{{
              item.worldActivityOrderResponse.activityDTO.startTime
            }}——{{ item.worldActivityOrderResponse.activityDTO.endTime }}
          </p>
          <p>
            活动地址：{{
              item.worldActivityOrderResponse.activityDTO.activityLocation
            }}
          </p>
        </div>

        <div class="activity_create_bg">
          <h4>预定人信息</h4>
          <div
            v-for="(it, index) in item.worldActivityOrderResponse.bizOrder
              .playerList"
            :key="index"
          >
            <div>
              <p style="width: 350px">
                联系人：{{ it.contactLastname }}{{ it.contactFirstname }}
              </p>
              <p>
                手机号：{{
                  item.worldActivityOrderResponse.bizOrder.contactPhone
                }}
              </p>
              <p>
                邮箱：{{
                  item.worldActivityOrderResponse.bizOrder.contactEmail
                }}
              </p>
              <p>
                护照ID：
                {{ it.passportId }}
              </p>
              <p>
                护照有效期：
                {{ it.passportEnd }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { hideLoading, showLoading } from "@/util/loading";

import Breadcrumb from "@/components/BreadCrumb";
import { format_time_date, format_time_date_noTime } from "@/util/funBusiness";

export default {
  components: { Breadcrumb },
  inject: ["reload"],
  data() {
    return {
      BreadcrumbCon: [
        {
          name: "行世界",
        },
        {
          name: "活动管理",
        },
        {
          name: "订单列表",
        },
        {
          name: "查看详情",
        },
      ], // 面包屑数据

      detailRow: [],

      failReason: "",
      cancelReason: "",

      day: "", //天
      hour: "", //时
      minute: "", //分
      second: "", //秒
      flag: false,
    };
  },

  mounted() {
    showLoading();
    const opt = {
      url: reqUrl.worldActivityOrderListOrder,
      method: "POST",
      params: JSON.stringify({
        activityId: "",
        orderId: this.$route.query.orderId,
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        } else if (res.data.code == 0) {
          this.detailRow = res.data.data.list;

          this.detailRow.map((item) => {
            this.changeItem(item);
          });
          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        hideLoading();
      },
    };
    request(opt);

    let time = setInterval(() => {
      if (this.flag == true) {
        clearInterval(time);
      }
      if (this.detailRow.length > 0) {
        this.timeDown();
      }
    }, 500);
  },

  props: { endTime: { type: String } },

  methods: {
    handleBack() {
      this.$router.push("/worldActivityOrder");
      localStorage.removeItem("worldActivityOrderDetailRowList");
    },

    handleClick(status, pay, payOrder) {
      if (status == "success") {
        this.$confirm("您确定要执行该操作吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            const opt = {
              url: reqUrl.payAuditNftUrl,
              method: "POST",
              params: JSON.stringify({
                result: 2,
                orderId: pay.orderId,
                id: pay.id,
                examineReason: "",
                auditor: localStorage.getItem("userName"),
              }),
              resFunc: (res) => {
                console.log(res.data);
                if (res.data.code == 0) {
                  this.$message({
                    type: "success",
                    message: "操作成功!",
                  });

                  this.refresh();
                }
                hideLoading();

                if (res.data.code != 0) {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message,
                  });
                  hideLoading();
                }
              },
              errFunc: (err) => {
                this.$notify.error({
                  title: "错误",
                  message: err,
                });
                console.log(err, "err1");
                hideLoading();
              },
            };
            request(opt);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      } else if (status == "fail") {
        this.$confirm("您确定要执行该操作吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            console.log(pay, "pay");
            showLoading();
            const opt = {
              url: reqUrl.payAuditNftUrl,
              method: "POST",
              params: JSON.stringify({
                result: 3,
                orderId: pay.orderId,
                id: pay.id,
                examineReason: this.failReason,
                auditor: localStorage.getItem("userName"),
              }),
              resFunc: (res) => {
                console.log(res.data);

                if (res.data.code == 0) {
                  this.$message({
                    type: "success",
                    message: "操作成功!",
                  });

                  this.refresh();
                  hideLoading();
                }

                if (res.data.code != 0) {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message,
                  });
                  hideLoading();
                }

                hideLoading();
              },
              errFunc: (err) => {
                this.$notify.error({
                  title: "错误",
                  message: err,
                });
                console.log(err, "err1");
                hideLoading();
              },
            };
            request(opt);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      } else if (status == "cancel") {
        this.$confirm("您确定要执行该操作吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          showLoading();
          const opt = {
            url: reqUrl.worldTravelCancelActivityOrder,
            method: "POST",
            params: JSON.stringify({
              orderId: pay.orderId,
              cancelReason: this.cancelReason,
              wallet: pay.wallet,
              value:
                payOrder.payType == 1
                  ? payOrder.amount
                  : payOrder.payType == 7
                  ? payOrder.amountUsdt
                  : payOrder.payType == 8
                  ? payOrder.amountToken
                  : 0,
              isUser: false,
            }),
            resFunc: (res) => {
              console.log(res.data);
              hideLoading();

              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });

                this.refresh();
                hideLoading();
              }

              if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              }
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              console.log(err, "err1");
              hideLoading();
            },
          };
          request(opt);
        });
      }
    },

    changeItem(item) {
      let worldResOrder = item.worldActivityOrderResponse.bizOrder;
      let worldResActivity = item.worldActivityOrderResponse.activityDTO;
      let worldResPay = item.worldActivityOrderResponse.payOrder;
      let payExamine = item.payExamineVO;

      if (worldResOrder.orderStatus == 1) {
        worldResOrder.orderStatus = "未支付";
      } else if (worldResOrder.orderStatus == 2) {
        worldResOrder.orderStatus = "支付审核中";
      } else if (
        worldResOrder.orderStatus == 3 &&
        (worldResActivity.activityStatus == 2 ||
          worldResActivity.activityStatus == 3)
      ) {
        worldResOrder.orderStatus = "活动已预订，未开始";
      } else if (
        worldResOrder.orderStatus == 3 &&
        worldResActivity.activityStatus == 4
      ) {
        worldResOrder.orderStatus = "活动已预订，进行中";
      } else if (
        worldResOrder.orderStatus == 3 &&
        worldResActivity.activityStatus == 5
      ) {
        worldResOrder.orderStatus = "活动已完成";
      } else if (worldResOrder.orderStatus == 3) {
        worldResOrder.orderStatus = "支付审核通过，已支付";
      } else if (worldResOrder.orderStatus == 4) {
        worldResOrder.orderStatus = "支付审核拒绝";
      } else if (worldResOrder.orderStatus == 5) {
        worldResOrder.orderStatus = "用户取消";
      } else if (worldResOrder.orderStatus == 6) {
        worldResOrder.orderStatus = "平台取消";
      }

      if (payExamine.payType == 1) {
        payExamine.payType = "余额账户";
      } else if (payExamine.payType == 2) {
        payExamine.payType = "CPLE积分账户";
      } else if (payExamine.payType == 3) {
        payExamine.payType = "支付宝账户";
      } else if (payExamine.payType == 4) {
        payExamine.payType = "微信账户";
      } else if (payExamine.payType == 5) {
        payExamine.payType = "京东账户";
      } else if (payExamine.payType == 6) {
        payExamine.payType = "转出额度";
      } else if (payExamine.payType == 7) {
        payExamine.payType = "USDT";
      } else if (payExamine.payType == 8) {
        payExamine.payType = "链上CPLE TOKEN";
      }

      worldResPay.createTime = format_time_date(worldResPay.createTime);
      worldResPay.payTime = format_time_date(worldResPay.payTime);
      worldResActivity.startTime = format_time_date_noTime(
        worldResActivity.startTime
      );
      worldResActivity.endTime = format_time_date_noTime(
        worldResActivity.endTime
      );

      if (worldResActivity.activityStatus == 1) {
        worldResActivity.activityStatus = "报名未开始";
      } else if (worldResActivity.activityStatus == 2) {
        worldResActivity.activityStatus = "报名中";
      } else if (worldResActivity.activityStatus == 3) {
        worldResActivity.activityStatus = "报名结束";
      } else if (worldResActivity.activityStatus == 4) {
        worldResActivity.activityStatus = "活动进行中";
      } else if (worldResActivity.activityStatus == 5) {
        worldResActivity.activityStatus = "活动结束";
      }

      if (worldResOrder.cancelType == 0) {
        worldResOrder.cancelType = worldResOrder.cancelReason;
      } else if (worldResOrder.cancelType == 1) {
        worldResOrder.cancelType = "行程取消";
      } else if (worldResOrder.cancelType == 2) {
        worldResOrder.cancelType = "信息填错";
      } else if (worldResOrder.cancelType == 3) {
        worldResOrder.cancelType = "预定时间太长";
      } else if (worldResOrder.cancelType == 4) {
        worldResOrder.cancelType = "质量差";
      } else if (worldResOrder.cancelType == 5) {
        worldResOrder.cancelType = "其他";
      }
    },

    timeDown() {
      if (this.detailRow[0].payExamineVO != null) {
        const endTime = new Date();
        const nowTime = new Date(this.detailRow[0].payExamineVO.payTime);
        let leftTime = parseInt((endTime.getTime() - nowTime.getTime()) / 1000);
        let d = parseInt(leftTime / (24 * 60 * 60));
        let h = this.formate(parseInt((leftTime / (60 * 60)) % 24));
        let m = this.formate(parseInt((leftTime / 60) % 60));
        let s = this.formate(parseInt(leftTime % 60));
        if (leftTime <= 0) {
          this.flag = true;
          this.$emit("time-end");
        }
        this.day = d; //天
        this.hour = h; //时
        this.minute = m; //分
        this.second = s; //秒
      }
    },

    formate(time) {
      if (time >= 10) {
        return time;
      } else {
        return `0${time}`;
      }
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../index.scss";

.el-button {
  margin-top: 10px;
  border-radius: 20px;
}
</style>
